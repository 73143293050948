<template>
	<div class="body column">
		<Toolbar />
		<div class="holiday_notice">Shipments will be shipped on the same day provided order is placed before 11am GMT time.<br>Please consult FAQ's for delivery times to ensure you have enough time to ship to your event.</div>
		<div class="holiday_notice">** The store is closed from Friday 20th Dec to Thursday 2nd Jan - <b>Last shipments out on the 15th but cannot guarantee a pre-Christmas delivery.</b> **</div>
		<div class="main">
			<div class="page">
				<nuxt />
			</div>
		</div>
		<Housekeeping />
	</div>
</template>

<script>
	import '~/components/filters';
	import Toolbar from '~/components/Toolbar.vue';
	import Housekeeping from '~/components/Housekeeping.vue';

	export default {
		components: {
			Toolbar,
			Housekeeping,
		},
		head() {
			return {
				link: [
					{
						rel: "preconnect",
						href: this.$store.state.global.api_path,
					},
				],
			};
		},
		methods: {
			load_storage(item) {
				const data = localStorage.getItem(item);
				if (data) {
					const json = JSON.parse(data);
					this.$store.commit(`${item}/set`, json);
				}
			}
		},
		errorCaptured(err, vm, info) {
			if (typeof err.message != 'string') {
				return false;
			}
			var data = {};
			if (typeof err.stack != 'undefined') {
				data.stacktrace = err.stack;
			}
			data.msg = err.message;
			data.script = err.fileName;
			data.line = err.lineNumber;
			data.col = err.columnNumber;
			if (document.activeElement && document.activeElement.id) {
				data.active = document.activeElement.id;
			}
			// hack while Chrome sort themselves out!
			if (err.message != 'ResizeObserver loop limit exceeded') {
				var xho = new XMLHttpRequest();
				xho.open('post', `https://${this.$store.state.global.is_dev ? 'dev-' : ''}support.asagroup.ie/public/api/js_error.php`, true);
				xho.setRequestHeader("Content-Type", "text/plain");
				xho.send(JSON.stringify(data));
			}
		},
		mounted() {
			switch (this.$i18n.locale) {
				case 'default': {
					this.$store.commit('session/set', {
						currency_symbol: "&dollar;",
						currency_code: "USD",
					});
					return;
				}
			}
			this.load_storage('session');
			this.load_storage('subcategories');
			this.load_storage('cart');

			var force_login = true;

			// otherwise force a log in if there isn't a payment key set
			if (this.$route.query.payment_key) force_login = false;
			if (this.$route.query.carriage_key) force_login = false;

			if (this.$store.state.session.signed_in == false && force_login) {
				this.$router.push('/auth');
			}

		}
	};
</script>